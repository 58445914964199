@tailwind base;
@tailwind components;
@tailwind utilities;

.member-info{
    @apply w-full flex flex-row justify-between items-center mt-2 text-sm
}

.member-info:first-child{
    @apply mt-0
}

.member-info-big{
    @apply flex flex-row items-center
}

.member-info-line{
    @apply flex-1 h-[1px] bg-gray-400 ml-4 
}

.member-info-label{
    @apply font-bold text-lg text-gray-400
}

.member-info-margin{
    @apply mt-2 mb-2 text-sm
}

.profile-field{
    @apply bg-transparent text-white border-b outline-none p-1 box-border
}

.profile-resources{
    @apply text-3xl mt-4
}

.applicant-buttons{
    @apply bg-white bg-opacity-30 rounded-full p-2
}

.profile-error{
    @apply text-red-500
}

.tech-team-container{
    @apply relative z-30 flex flex-col items-center mt-[30px] mb-[75px]
}

.tech-team-label{
    @apply text-center border-2 px-2 text-2xl mt-4 rounded-lg bg-gray-700 bg-opacity-30
}

.tech-team-techs{
    @apply relative flex flex-wrap justify-center z-20 mb-[100px]
}

.profile-margins {
  @apply mt-2
}

.profile-parent-card {
  @apply w-full h-full relative bg-slate-900 rounded-xl text-white overflow-x-hidden overflow-y-auto
}