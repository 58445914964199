/* GENERAL _________________________________________ */
html, body {
    background-color: black;
    font-family: 'Montserrat', sans-serif;
    color: var(--white);
}
/* FONTS ___________________________________________ */
h1 {
    font-size: min(calc(18px + 1vw), 48px);
}
h2 {
    font-size: min(calc(14px + 0.3vw), 32px);
}
h3 {
    font-size: min(calc(12px + 0.3vw), 24px);
}

h4, p {
    font-size: min(calc(15px + 0.6vw), 32px);
}

mark {
    color: rgb(248, 240, 240);
    background-color:rgba(0, 0, 0, 0);
}

.montserrat {
    font-family: 'Montserrat', sans-serif;
}
.roboto {
    font-family: 'Roboto Condensed', sans-serif;
}

.card-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px
}

.card-spacing {
    padding: 30px;
}

.animated-divider {
    width: 90%;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 1.5px;
    animation: expand 500ms ease-out;
}

.roboto-mono {
    font-family: 'Roboto Mono', monospace;
}
.fadein {
    animation: fadein 750ms ease-in-out;
}
.blur {
    backdrop-filter: blur(20px);
    background-color: rgba(0,0,0,0.2);
    animation: blurbg 500ms ease-in-out;
}
.menuitem {
    transition: 250ms ease-in-out;
    color: #ed3d63;
}
.menuitem:hover {
    color: #8d3fd0;
}
.tab-1 {
    margin-left: 3rem;
}
.tab-2 {
    margin-left: 6rem;
}
.tab-3 {
    margin-left: 9rem;
}
.code-comment {
    opacity: 0.7;
}

/*  */

/* SLIDES */

.slideshowDot {
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
  
    background-color: #c4c4c484;
}

.slideshowDot.active {
    background-color: #ffffff;
}

.slideshowDot.activeButCooler {
  background-color: #ff8080;
}

#menu {
    color: rgb(248, 240, 240);
    border-top: solid rgb(248, 240, 240) 2.6px;
    animation: menu 800ms ease-in-out;
    box-shadow: inset 100vw 100vw black;
}
#menubg {
    background-color: black;
}

#custom:hover > #custom_button{
    background: rgba(22, 26, 88, 0.25);
}

#custom_button {
    display: inline-flex;
    justify-content: center;
    transition: 250ms ease-in-out;
    border: solid 3px;
    border-radius: 50%;
    border-color: rgb(22, 26, 88);
    background: rgba(22, 26, 88, 0)
}

#custom_button:hover {
    background: rgba(22, 26, 88, 0.25)
}

#progress {
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
    border-top: solid rgb(248, 240, 240) 2.6px;
}
#x-button {
    display: inline-flex;
    height: 28px;
    width: 28px;
    padding: 0px;
    justify-content: center;
    transition: 250ms ease-in-out;
    border: solid 1px;
    border-radius: 50%;
    border-color: rgb(248, 240, 240);
    background: rgba(248, 240, 240, 0)
}
#x-button:hover {
    background: rgba(248, 240, 240, 0.25)
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
}
@keyframes blurbg {
    0% {
        backdrop-filter: blur(5px);
    }
}
@keyframes menu {
    0% {
        box-shadow: inset 0vw 0vw black;
    }
}
@keyframes expand {
    0% {
        width: 0%;
    }
}

